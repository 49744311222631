import React from "react";
import Navbar from "../../component/navbar/Navbar";
// import Contact from "../../component/contact/Contact";
import Card from "../../component/card/Card";
import Footer from "../../component/footer/Footer";

const Products = () => {
  return (
    <div className="productsContainer">
      <Navbar />
      <Card />
      <Footer />
      {/* <Contact /> */}
    </div>
  );
};

export default Products;
