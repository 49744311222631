export const ProductPosts = [
  {
    id: 1,
    productsimg: "/img/products/prAlo.gif",
    informationimg: "/img/information/alo.gif",
  },
  {
    id: 2,
    productsimg: "/img/products/prBlueberry.gif",
    informationimg: "/img/information/blueberry.gif",
  },
  {
    id: 3,
    productsimg: "/img/products/prCoconut.gif",
    informationimg: "/img/information/coco.gif",
  },
  {
    id: 4,
    productsimg: "/img/products/prMango.gif",
    informationimg: "/img/information/mango.gif",
  },
  {
    id: 5,
    productsimg: "/img/products/prMangoMini.gif",
    informationimg: "/img/information/mongomini.gif",
  },

  {
    id: 6,
    productsimg: "/img/products/prOrange.gif",
    informationimg: "/img/information/orange.gif",
  },
  {
    id: 7,
    productsimg: "/img/products/prPineapple.gif",
    informationimg: "/img/information/pineapple.gif",
  },
  {
    id: 8,
    productsimg: "/img/products/prPomegranate.gif",
    informationimg: "/img/information/pomegranate.gif",
  },
];
