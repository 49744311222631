import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./banner.css";
import Product1 from "../../assets/products/pr01.png";
import Product2 from "../../assets/products/pr02.png";

import Product4 from "../../assets/products/pr04.png";
import { t } from "i18next";

const Banner = () => {
  const productData = [Product1, Product2, Product4];
  const { t, i18n } = useTranslation();
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => fixIndex(prevSlide + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const fixIndex = (index) => {
    if (index < 0) {
      return productData.length - 1;
    } else if (index >= productData.length) {
      return 0;
    } else {
      return index;
    }
  };

  return (
    <div className="banner">
      {/* Left */}
      <div className="bannerRight">
        <div className="bannerRightContent">
          <h1 className="bannerRightTitle">{t("bannerTitle")}</h1>
        </div>
      </div>

      {/* Right */}
      <div className="bannerLeft">
        <div className="bannerLeftSlider">
          <div className="bannerLeftSlides">
            <div className="bannerLeftSlide">
              {productData.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt=""
                  className={` ${
                    index === slide
                      ? "bannerLeftSlideImgActive"
                      : "bannerLeftSlideImg"
                  }`}
                  // className={`bar ${menuOpen ? "open" : ""}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
