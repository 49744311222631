import React, { useState } from "react";

import "./cards.css";
import { ProductPosts } from "../../productsData";
import { useTranslation } from "react-i18next";

const Cards = () => {
  const { t, i18n } = useTranslation();
  const [slide, setSlide] = useState(0);

  return (
    <div className="cards">
      <div className="cardsRight">
        <div className="cardsTop">
          <h3 className="cardsTopTitle">{t("cardsTitle")}</h3>
          <div className="cardsTopLine"></div>
        </div>
        <p>{t("cardsText")}</p>
      </div>
      <div className="cardsLeft">
        <div className="cardsFrame">
          <div
            className="cardsSlider"
            style={{ transform: `translateX(${slide * -10}%)` }}
          >
            {ProductPosts.map((i) => {
              return (
                <img
                  className="cardsSliderImg"
                  key={i.id}
                  src={i.productsimg}
                  alt=""
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
