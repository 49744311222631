import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationCity,
  Phone,
  PostAddRounded,
  Telegram,
  WhatsApp,
  X,
} from "@mui/icons-material";
import "./footerStyles.css";
import React, { useState } from "react";

const questions = [
  {
    question: "حداقل مدت‌زمان اجاره ون در شیراز چقدر است؟",
    answer: "باشید.",
  },
];

const Footer = () => {
  return (
    <>
      <div className="footerContainer">
        <div className="footerTop">
          <div className="footer">
            <div className="footerRight">
              <b>لوتوس شهد کاسپین</b>
              <br />
              شهرتو خوشمزه کن👍
            </div>
            <div className="footerCenter">
              <div className=" footerCenterItem">
                <LocationCity style={{ color: "#ffc83d" }} />
                <h3 className="footerCenterItemTitle">Address</h3>
                <p className="footerCenterItemText">
                  Qazvin industrial township, Rasht, Qazvin Highway 30
                  kilometer, Qazvin, Iran
                </p>
              </div>
              <div className="footerCenterItem">
                <Phone style={{ color: "#ffc83d" }} />
                <h3 className="footerCenterItemTitle">Phone</h3>
                <p className="footerCenterItemText"> 02833762488</p>
              </div>
              <div className="footerCenterItem">
                <PostAddRounded style={{ color: "#ffc83d" }} />
                <h3 className="footerCenterItemTitle">Postal code</h3>
                <p className="footerCenterItemText">3497118328</p>
              </div>
            </div>
            <div className="footerLeft">
              <WhatsApp className="footerLeftIcon" />
              <Telegram className="footerLeftIcon" />
              <Instagram className="footerLeftIcon" />
              <X className="footerLeftIcon" />
              <Facebook className="footerLeftIcon" />
              <LinkedIn className="footerLeftIcon" />
            </div>
          </div>
        </div>
        <div className="footerBottomLine">© LOTUS SHAHD CASPIAN</div>
      </div>
    </>
  );
};

export default Footer;
