// import "./home.css";
import Navbar from "../../component/navbar/Navbar";
// import Contact from "../../component/contact/Contact";
import Feed from "../../component/feed/Feed";
import Cards from "../../component/cards/Cards";
import Banner from "../../component/banner/Banner";
import About from "../../component/about/About";
import Footer from "../../component/footer/Footer";

const Home = () => {
  return (
    <>
      <div className="home">
        <Navbar />
        <Banner />
        <Feed />
        <Cards />
        <About />
        <Footer />
        {/* <Contact /> */}
      </div>
    </>
  );
};

export default Home;
