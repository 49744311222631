import React from "react";
import "./languageSelectorStyles.css";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="languageSelectorContainer">
      <button
        className="languageSelectorButton"
        onClick={() => changeLanguage("fa")}
      >
        فارسی
      </button>
      <button
        className="languageSelectorButton"
        onClick={() => changeLanguage("en")}
      >
        English
      </button>
      <button
        className="languageSelectorButton"
        onClick={() => changeLanguage("ar")}
      >
        عربی
      </button>
    </div>
  );
};

export default LanguageSelector;
