import { useTranslation } from "react-i18next";
import "./about.css";
const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="about">
      <div className="aboutTop">
        <h2 className="aboutTopTitle">{t("ourBeginning")}</h2>
        <div className="aboutTopLine"></div>
      </div>
      <div className="aboutBottom">
        <p className="aboutBottomText">{t("ourBeginningText")}</p>
      </div>
    </div>
  );
};

export default About;
