// import { Chat, Notifications, Person, Search } from "@material-ui/icons";
import { ProductPosts } from '../../productsData';
import './card.css';

export default function Card() {
  return (
    <div className="container">
      {ProductPosts.map((i) => {
        return (
          <div className="card">
            <div className="cardLeft">
              <img src={i.informationimg} alt="" className="cardLeftImg" />
            </div>
            <div className="cardRight">
              <img src={i.productsimg} alt="" className="cardRightImg" />
            </div>
          </div>
        );
      })}
    </div>
  );
}
