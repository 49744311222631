import React, { useState, useEffect } from "react";
import "./navbarStyles.css";
import { useTranslation } from "react-i18next";
import {
  Language,
  LiquorRounded,
  PhoneRounded,
  WhatsApp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import LanguageSelector from "../languageSelector/LanguageSelector";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false); // استیت برای نگهداری وضعیت لیست کشویی
  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleLanguageSelector = () => {
    setLanguageSelectorOpen(!languageSelectorOpen); // تغییر وضعیت استیت لیست کشویی
  };
  return (
    <>
      <div className="navbarContainer">
        <div className="navbarRight">
          <button className="hamburgerMenuButton" onClick={toggleMenu}>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          </button>
          <Link to="/">
            <LiquorRounded
              style={{ fontSize: "45px", color: "#fff" }}
              className={`navbarRightLogo ${menuOpen ? "open" : ""}`}
            />
          </Link>
        </div>

        <div className={`sidebar ${menuOpen ? "open" : ""}`}>
          <ul className="sidebarUL">
            <li className="sidebarLi"> {t("aboutUs")}</li>
            <Link className="link" to="/products">
              <li className="sidebarLi">{t("products")}</li>
            </Link>
          </ul>
        </div>

        <div className={`navbarCenter ${menuOpen ? "open" : ""}`}>
          <ul className="navbarCenterUL">
            <li className="navbarCenterLi">{t("aboutUs")}</li>
            <Link className="link" to="/products">
              <li className="navbarCenterLi">{t("products")}</li>
            </Link>
          </ul>
        </div>
        <div className="navbarLeft">
          <div className="navbarIcons">
            <div
              className="languageSelectorIcon"
              onClick={toggleLanguageSelector}
            >
              {" "}
              {/* اضافه کردن onClick برای باز کردن لیست کشویی */}
              <Language style={{ fontSize: "23px" }} className="navbarIcon" />
              {languageSelectorOpen && <LanguageSelector />}{" "}
              {/* نمایش کامپوننت LanguageSelector در صورت باز بودن لیست کشویی */}
            </div>
            <PhoneRounded style={{ fontSize: "23px" }} className="navbarIcon" />
            <WhatsApp style={{ fontSize: "23px" }} className="navbarIcon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
