import {
  LiquorOutlined,
  LocalBarOutlined,
  LocalDrinkOutlined,
  SportsBarOutlined,
  WineBarOutlined,
} from "@mui/icons-material";
import "./feed.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Feed = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="feed">
      <div className="feedTop">
        <div className="feedTitle">
          <h2 className="feedTitleText">{t("feedTitle")}</h2>
          <div className="feedTitleLine"></div>
        </div>
      </div>
      <div className="feedBottom">
        <Link className="link" to={"/products"}>
          <div className="feedBottomItem">
            <WineBarOutlined
              style={{ fontSize: 50 }}
              className="feedBottomItemIcon"
            />

            <h3 className="feedBottomItemTitle"> {t("feedDrinks")}</h3>
          </div>
        </Link>
        <Link className="link" to={"/products"}>
          <div className="feedBottomItem">
            <LiquorOutlined
              style={{ fontSize: 50 }}
              className="feedBottomItemIcon"
            />
            <h3 className="feedBottomItemTitle">{t("feedJuice")}</h3>
          </div>
        </Link>
        <div className="feedBottomItem">
          <LocalDrinkOutlined
            style={{ fontSize: 50 }}
            className="feedBottomItemIcon"
          />
          <h3 className="feedBottomItemTitle">{t("feedMineralwater")} </h3>
        </div>
        {/* <div className="feedBottomItem">
          <SportsBarOutlined className="feedBottomItemIcon" />
          <h3 className="feedBottomItemTitle">نوشیدنی گازدار</h3>
        </div> */}
        <div className="feedBottomItem">
          <LocalBarOutlined
            style={{ fontSize: 50 }}
            className="feedBottomItemIcon"
          />
          <h3 className="feedBottomItemTitle">{t("feedMaltdrinks")} </h3>
        </div>
      </div>
    </div>
  );
};

export default Feed;
